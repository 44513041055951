<template>
    <div class="p-2">
        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
            <div class="flex-wrap">

            </div>
        </div>

        <div class="p-3">
            <h3>Pricing</h3>
                <surround-loader :is-loading="isLoading">
                    <div class="border bg-white rounded p-3">
                        <div class="row">
                            <div class="col-6">
                                <b-form-group
                                        label-size="sm"
                                        label="Cost per application:">
                                    <b-form-input type="number" size="sm" v-model="cost_model.application_cost"
                                                  placeholder="Cost per application"></b-form-input>
                                    <error-display ident="application_cost" v-model="errors"></error-display>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group
                                        label-size="sm"
                                        label="Cost per doc check:">
                                    <b-form-input type="number" size="sm" v-model="cost_model.doc_check_cost"
                                                  placeholder="Cost per doc check"></b-form-input>
                                    <error-display ident="doc_check_cost" v-model="errors"></error-display>
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group
                                        label-size="sm"
                                        label="Billing frequency:">
                                    <b-select size="sm" v-model="cost_model.frequency"
                                              :options="frequencyOptions"
                                              placeholder=""></b-select>
                                    <error-display ident="frequency" v-model="errors"></error-display>
                                </b-form-group>
                            </div>
                            <div class="px-3 d-flex justify-content-end col-12">
                                <b-button class="flex-wrap" variant="success" @click="saveCostModel">Save
                                    default pricing
                                </b-button>
                            </div>
                        </div>
                    </div>
                </surround-loader>
        </div>

        <div class="p-3">
            <h3>Other settings</h3>
            <surround-loader :is-loading="isLoadingSettings">
                <div class="border bg-white rounded p-3">

                    <b-form-group
                            v-for="setting in settings"
                            label-size="sm"
                            :key="setting.setting_key"
                            :label="setting.setting_name+':'">
                        <b-form-input size="sm" v-model="setting.setting_value"
                                      placeholder=""></b-form-input>
                        <error-display :ident="setting.setting_key" v-model="errors"></error-display>
                    </b-form-group>

                    <div class="d-flex justify-content-end ">
                        <b-button class="flex-wrap" variant="success" @click="saveSettings">Save settings
                        </b-button>
                    </div>
                </div>
            </surround-loader>
        </div>

    </div>
</template>

<script>
    import ErrorDisplay from "../../../../components/form_inputs/ErrorDisplay";
    import SurroundLoader from "../../../../components/SurroundLoader";
    import {getResource, saveResource} from "../../../../modules/api/methods";
    import {basicSettings, costModel} from "../../../../modules/api/endpoints";
    import {containsErrors} from "../../../../modules/helpers/helpers";
    import _ from 'lodash';

    export default {
        name: "general_settings",
        components: {SurroundLoader, ErrorDisplay},
        data: function () {
            return {
                isLoading: false,
                isLoadingSettings: false,
                settings: [],
                errors: {},
                frequencyOptions: [
                    {value: 'monthly', text: 'Monthly'},
                    {value: 'quarterly', text: 'Quarterly'},
                ],
                cost_model: {
                    application_cost: null,
                    doc_check_cost: null,
                    frequency: null,
                },
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'General settings',
                        active: true
                    }
                ]
            }
        },
        created() {
            this.loadCostModel();
            this.loadSettings();
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if (newVal.params.data_updated !== undefined) {
                        this.request = _.clone(this.request)
                    }
                }
            }
        },
        methods: {
            loadCostModel() {
                this.isLoading = true;
                getResource(costModel).then((resp) => {
                    this.cost_model = resp.data.success.data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveCostModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(costModel, this.cost_model).then((resp) => {
                    this.isLoading = false;
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            },
            loadSettings() {
                this.isLoadingSettings = true;
                getResource(basicSettings).then((resp) => {
                    this.settings = resp.data.success.data;
                    this.isLoadingSettings = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoadingSettings = false;
                });
            },
            saveSettings() {
                this.isLoadingSettings = true;
                this.errors = {};
                var postData = {};
                _.each(this.settings, (setting) => {
                    postData[setting.setting_key] = setting.setting_value;
                });
                saveResource(basicSettings, postData).then((resp) => {
                    this.isLoadingSettings = false;
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoadingSettings = false;
                });
            },
        }

    }
</script>
